import {
  CommonModule,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomepageComponent } from '../homepage/homepage.component';
import { AppstoreButtonsComponent } from '../shared/appstore-buttons/appstore-buttons.component';
import { ArticleTeaserComponent } from '../shared/article-teaser/article-teaser.component';
import { AusgezeichnetBarComponent } from '../shared/ausgezeichnet-bar/ausgezeichnet-bar.component';
import { ButtonComponent } from '../shared/button/button.component';
import { DisrupterComponent } from '../shared/disrupter/disrupter.component';
import { LanguageSwitcherComponent } from '../shared/language-switcher/language-switcher.component';
import { MobileAppSectionComponent } from '../shared/mobile-app-section/mobile-app-section.component';
import { MoreTeaserComponent } from '../shared/more-teaser/more-teaser.component';
import { ProgramTeaserComponent } from '../shared/program-teaser/program-teaser.component';
import { RatingsCarouselComponent } from '../shared/ratings-carousel/ratings-carousel.component';
import { RecommendedByComponent } from '../shared/recommended-by/recommended-by.component';
import { TeacherTeaserComponent } from '../shared/teacher-teaser/teacher-teaser.component';
import { TeaserSliderComponent } from '../shared/teaser-slider/teaser-slider.component';
import { VideoTeaserComponent } from '../shared/video-teaser/video-teaser.component';
import { AppPictogramSvg } from '../svg/app-pictogram/app-pictogram.svg';
import { AsanaWarriorPictogramSvg } from '../svg/asana-warrior-pictogram/asana-warrior-pictogram.svg';
import { OmPictogramSvg } from '../svg/om-pictogram/om-pictogram.svg';
import { OnlineYogaPictogramSvg } from '../svg/online-yoga-pictogram/online-yoga-pictogram.svg';
import { VideoUpdatesPictogramSvg } from '../svg/video-updates-pictogram/video-updates-pictogram.svg';
import { WaveSvg } from '../svg/wave/wave.svg';
import { AcademySectionComponent } from './academy-section/academy-section.component';
import { ArticlesSectionComponent } from './articles-section/articles-section.component';
import { BenefitsSectionComponent } from './benefits-section/benefits-section.component';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { CtaButtonSectionComponent } from './cta-button-section/cta-button-section.component';
import { HomepageState } from './homepage.state';
import { LiveSectionComponent } from './live-section/live-section.component';
import { MigrationInfoComponent } from './migration-info/migration-info.component';
import { ProgramsSectionComponent } from './programs-section/programs-section.component';
import { RecommendedBySectionComponent } from './recommended-by-section/recommended-by-section.component';
import { TeachersSectionComponent } from './teachers-section/teachers-section.component';
import { TopSectionComponent } from './top-section/top-section.component';
import { VideosSectionComponent } from './videos-section/videos-section.component';

@NgModule({
  declarations: [
    AcademySectionComponent,
    ArticlesSectionComponent,
    BenefitsSectionComponent,
    CarouselSlideComponent,
    CtaButtonSectionComponent,
    HomepageComponent,
    TopSectionComponent,
    LiveSectionComponent,
    ProgramsSectionComponent,
    RecommendedBySectionComponent,
    TeachersSectionComponent,
    VideosSectionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([HomepageState]),
    NgOptimizedImage,
    AppPictogramSvg,
    AppstoreButtonsComponent,
    ArticleTeaserComponent,
    AsanaWarriorPictogramSvg,
    AusgezeichnetBarComponent,
    CarouselModule,
    DisrupterComponent,
    MobileAppSectionComponent,
    OmPictogramSvg,
    OnlineYogaPictogramSvg,
    ProgramTeaserComponent,
    RatingsCarouselComponent,
    RecommendedByComponent,
    TeacherTeaserComponent,
    VideoTeaserComponent,
    VideoUpdatesPictogramSvg,
    WaveSvg,
    ButtonComponent,
    LanguageSwitcherComponent,
    MigrationInfoComponent,
    TeaserSliderComponent,
    MoreTeaserComponent,
  ],
  exports: [HomepageComponent],
  providers: [provideImageKitLoader('https://ik.imagekit.io/ye')],
})
export class HomepageModule {}
