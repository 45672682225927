<div class="bookmarks-list-page" *ngIf="bookmarksList; else noList">
  <app-bookmarks-list-page-header [bookmarksList]="bookmarksList" class="is-block mb-3"></app-bookmarks-list-page-header>
  <app-sort-bookmarks-list-wrapper></app-sort-bookmarks-list-wrapper>
  <div cdkDropListGroup mixedCdkDragDrop [orientation]="(viewport$ | async) === 'mobile' ? 'vertical' : 'horizontal'"
    class="is-flex is-justify-content-space-around-touch is-flex-wrap-wrap m-minus-3" (dropped)="onDrop($event)">
    <!-- implemented as described here: https://github.com/rosejoe47/angular-mixed-cdk-drag-drop -->
    @for (teaser of bookmarksItems; track teaser.info.id) {
    <span cdkDropList mixedCdkDropList>
      <div cdkDrag class="m-3">
        <app-video-teaser [teaser]="teaser.info" [breadcrumb]="['Favoritenlisten', bookmarksList.title]" size="small" [draggable]="true" />
      </div>
    </span>
    }
  </div>
  @if(bookmarksItems.length === 0) {
  <app-teaser-slider>
    <app-teaser-slider-empty-message *ngIf="!bookmarksItems.length" [displayHeadline]="false">
      <div class="empty-fav-list-container mt-5">
        <object type="image/svg+xml" data="https://cdn.yogaeasy.de/production/uploads/components/dashboard/svg/heart-with-background.svg"
          class="empty-fav-list-svg"></object>
        <div>
          <div class="empty-fav-list-container-text pl-3 pr-3" i18n>
            <span class="has-text-weight-bold is-block empty-fav-list-container-text-title">Noch keine Favoriten?</span>
            <span>Markiere deine Lieblingsvideos und speichere sie in Listen, um sie später einfach wiederzufinden.</span>
          </div>
          <app-button class="empty-fav-list-container-button pl-3 pr-3 mt-4 mt-sm-0 mb-4" size="medium" type="secondary"
            [href]="'/video-kategorien'" i18n>Videos
            entdecken</app-button>
        </div>
      </div>
    </app-teaser-slider-empty-message>
  </app-teaser-slider>
  }
</div>
<ng-template #noList>
  <div *ngIf="bookmarksListError$ | async as bookmarksListError; else loading">
    {{ bookmarksListError }}
  </div>
  <ng-template #loading><app-ye-loading-spinner></app-ye-loading-spinner></ng-template>
</ng-template>