import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bookmarks-list-favorite-teaser',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bookmarks-list-favorite-teaser.component.html',
  styleUrls: ['./bookmarks-list-favorite-teaser.component.sass'],
})
export class BookmarksListFavoriteTeaserComponent {
  @Input() itemsCount = 0;
}
