import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SearchSvg } from 'src/app/svg/search/search.svg';

@Component({
  selector: 'app-more-teaser',
  standalone: true,
  imports: [CommonModule, SearchSvg],
  templateUrl: './more-teaser.component.html',
  styleUrls: ['./more-teaser.component.sass'],
})
export class MoreTeaserComponent {
  @Input() type:
    | 'article'
    | 'bookmarks'
    | 'podcast'
    | 'program'
    | 'teacher'
    | 'video'
    | 'favorite';
  @Input() count?: number;
  @Input() href: string;
  @Input() hideText? = false;
  @Input() customCss?: string;
}
