<div class="info-container">
  <div class="image-container">
    <!-- <img src="production/uploads/components/migration-info/migration-info.jpg" width="750" height="672" alt="Yogaeasy Migration Info"
      class="img-mobile"> -->
    <div class="svg-container">
      <object type="image/svg+xml" data="https://cdn.yogaeasy.de/production/uploads/components/homepage/banners/EY_YE_combined_logo.svg"
        class="svg"></object>
    </div>
  </div>
  <div class="text-container">
    <div class="is-size-4  has-text-deep-sea-green has-text-weight-bold has-text-centered mb-2 mx-4 px-4">
      <ng-container>
        Welcome to YogaEasy!
      </ng-container>
    </div>
    <div class="is-size-5-and-half has-text-kokoda has-text-centered is-width-100-tablet-only px-2-tablet-only is-width-341">
      <div class="m-2">
        EkhartYoga is now part of YogaEasy, offering the same great content with new possibilities.
      </div>
      <div class="mb-4">
        <div class="has-text-weight-bold">
          Already an EkhartYoga member?
        </div>
        <div class="">
          Set a new password to activate your account and access your saved content.
        </div>
      </div>
      <div>
        <a href="/user/password_reset" class="button  banner-button is-outlined is-rounded has-text-weight-semibold">
          Continue
        </a>
      </div>
    </div>
  </div>
</div>