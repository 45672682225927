<app-teaser-slider-empty-message slot="empty" [displayHeadline]="false">
  @if(showHeadline) {
  <div class="headlines">
    <h3 class="headline" i18n>
      Favoritenlisten
    </h3>
    <div class="subheadline" i18n>
      Deine liebsten Videos und selbst erstellte Listen
    </div>
  </div>
  }
  <div class="empty-fav-list-container">
    <object type="image/svg+xml" data="https://cdn.yogaeasy.de/production/uploads/components/dashboard/svg/heart-with-background.svg"
      class="empty-fav-list-svg"></object>
    <div>
      <div class="empty-fav-list-container-text pl-3 pr-3" i18n>
        <span class="has-text-weight-bold is-block empty-fav-list-container-text-title">Noch keine Favoriten?</span>
        <span>Markiere deine Lieblingsvideos und speichere sie in Listen, um sie später einfach wiederzufinden.</span>
      </div>
      <app-button class="empty-fav-list-container-button pl-3 pr-3 mt-4 mb-4 mt-sm-0" size="medium" type="secondary"
        (onClick)="openModal()">Liste
        erstellen</app-button>
    </div>
  </div>
</app-teaser-slider-empty-message>