import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  Component,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ArrowBackSvg } from 'src/app/svg/arrow-back/arrow-back.svg';
import { ArrowSvg } from 'src/app/svg/arrow/arrow.svg';
import { FadeInOutAnimation } from '../animations';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-teaser-slider',
  standalone: true,
  imports: [CommonModule, ArrowSvg, ArrowBackSvg, ButtonComponent],
  templateUrl: './teaser-slider.component.html',
  styleUrls: ['./teaser-slider.component.sass'],
  animations: [FadeInOutAnimation],
})
export class TeaserSliderComponent implements AfterContentChecked {
  @Input() headline?: string;
  @Input() headlineLink?: string;
  @Input() subheadline?: string;
  @Input() showCount? = false;
  @Input() count?: number;
  @Input() showAllLink?: string;

  private scrollValue: number;
  @ViewChild('slider') private slider;

  // empty: no teasers, no carets, emptyMessage is shown or slider is hidden
  // fitting: teasers do not fill the horizontal space, carets are hidden
  // overflown: teasers do overflow the horizontal space, carets are shown
  // TODO: hide the teaser-slider (with headline) when empty and no ng-content[slot=empty]
  public state: 'empty' | 'fitting' | 'overflown';
  public showLeftCaret = false;
  public showRightCaret = false;

  constructor(private window: Window) {}

  ngAfterContentChecked(): void {
    this.calculateScrollValue();
    this.checkCarets();
  }

  @HostListener('window:resize')
  refresh() {
    this.calculateScrollValue();
    this.checkCarets();
  }

  calculateScrollValue() {
    const children = this.slider?.nativeElement?.children;

    if (children?.length) {
      const visibleWidth = this.slider.nativeElement.offsetWidth;
      const totalWidth = this.slider.nativeElement.scrollWidth;

      if (totalWidth <= visibleWidth) {
        this.state = 'fitting';
      } else {
        this.state = 'overflown';
        const teaserWidth = children[0]?.getBoundingClientRect()?.width;
        const marginRight = window.getComputedStyle(children[0])?.marginRight; // we assume that this is in format '**px'

        if (teaserWidth && marginRight) {
          const totalWidth = teaserWidth + parseInt(marginRight, 10);
          this.scrollValue = totalWidth * Math.floor(visibleWidth / totalWidth);
        } else {
          // use fallback value
          this.scrollValue = visibleWidth;
        }
      }
    } else {
      this.state = 'empty';
    }
  }

  checkCarets() {
    if (this.slider && this.state === 'overflown') {
      const currentPosition = this.slider.nativeElement.scrollLeft;
      const visibleWidth = this.slider.nativeElement.offsetWidth;
      const totalWidth = this.slider.nativeElement.scrollWidth;

      this.showLeftCaret = currentPosition > 0;
      this.showRightCaret = currentPosition + visibleWidth < totalWidth;
    } else {
      this.showLeftCaret = false;
      this.showRightCaret = false;
    }
  }

  scrollLeft() {
    this.slider.nativeElement.scrollBy({
      left: -this.scrollValue,
      behavior: 'smooth',
    });
  }

  scrollRight() {
    this.slider.nativeElement.scrollBy({
      left: this.scrollValue,
      behavior: 'smooth',
    });
  }
}
