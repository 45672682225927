<a href="homepage/videos/meine-favoriten" class="is-block bookmarks-list-favorite-teaser">
  <object type="image/svg+xml" data="https://cdn.yogaeasy.de/production/uploads/components/dashboard/svg/heart-with-background.svg"
    class="empty-fav-list-svg heart"></object>
  <div class="text-block is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
    <h4 class="teaser-title" i18n>Meine Favoriten</h4>
    @if(itemsCount === 0) {
    <span i18n>0 Videos</span>
    } @else if(itemsCount >= 0) {
    <span class="count">
      @if (itemsCount === 1) {
      <span i18n>1 Video</span>
      } @else {
      <span i18n>{{ itemsCount }} Videos</span>
      }
    </span>
    }
  </div>
</a>