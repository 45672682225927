import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreateBookmarksListModalComponent } from 'src/app/bookmarks/create-bookmarks-list-modal/create-bookmarks-list-modal.component';
import { CoreActions } from 'src/app/core/core.actions';
import { ButtonComponent } from '../../shared/button/button.component';
import { TeaserSliderEmptyMessageComponent } from '../../shared/teaser-slider-empty-message/teaser-slider-empty-message.component';

@Component({
  selector: 'app-empty-favorite-message',
  standalone: true,
  imports: [ButtonComponent, TeaserSliderEmptyMessageComponent],
  templateUrl: './empty-favorite-message.component.html',
  styleUrl: './empty-favorite-message.component.sass',
})
export class EmptyFavoriteMessageComponent {
  @Input() showHeadline = true;
  constructor(private store: Store) {}
  openModal() {
    this.store.dispatch(
      new CoreActions.OpenModal(
        CreateBookmarksListModalComponent.MODAL_ID,
        CreateBookmarksListModalComponent,
        {
          windowClass: 'create-bookmarks-list-modal-window',
        },
      ),
    );
  }
}
