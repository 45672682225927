import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { MixedCdkDragDropModule } from 'angular-mixed-cdk-drag-drop';
import { EmptyFavoriteMessageComponent } from '../dashboard/empty-favorite-message/empty-favorite-message.component';
import { BookmarksListFavoriteTeaserComponent } from '../shared/bookmarks-list-favorite-teaser/bookmarks-list-favorite-teaser.component';
import { BookmarksListTeaserComponent } from '../shared/bookmarks-list-teaser/bookmarks-list-teaser.component';
import { ButtonComponent } from '../shared/button/button.component';
import { IconButtonLabelComponent } from '../shared/icon-button-label/icon-button-label.component';
import { IconButtonComponent } from '../shared/icon-button/icon-button.component';
import { LoadingCircleComponent } from '../shared/loading-circle/loading-circle.component';
import { TeaserSliderEmptyMessageComponent } from '../shared/teaser-slider-empty-message/teaser-slider-empty-message.component';
import { TeaserSliderComponent } from '../shared/teaser-slider/teaser-slider.component';
import { VideoTeaserPlaceholderComponent } from '../shared/video-teaser/video-teaser-placeholder/video-teaser-placeholder.component';
import { VideoTeaserComponent } from '../shared/video-teaser/video-teaser.component';
import { YeLoadingSpinnerComponent } from '../shared/ye-loading-spinner/ye-loading-spinner.component';
import { AddSvg } from '../svg/add/add.svg';
import { ArrowBackSvg } from '../svg/arrow-back/arrow-back.svg';
import { ArrowSvg } from '../svg/arrow/arrow.svg';
import { CheckmarkSvg } from '../svg/checkmark/checkmark.svg';
import { DeleteSvg } from '../svg/delete/delete.svg';
import { EditSvg } from '../svg/edit/edit.svg';
import { ExitSvg } from '../svg/exit/exit.svg';
import { HeartFilledSvg } from '../svg/heart-filled/heart-filled.svg';
import { LogoIcon } from '../svg/logo-icon/logo-icon.svg';
import { PlusSvg } from '../svg/plus/plus.svg';
import { AddBookmarksListTeaserComponent } from './add-bookmarks-list-teaser/add-bookmarks-list-teaser.component';
import { BookmarksListPageHeaderComponent } from './bookmarks-list-page/bookmarks-list-page-header/bookmarks-list-page-header.component';
import { BookmarksListPageComponent } from './bookmarks-list-page/bookmarks-list-page.component';
import { BookmarksListsPageComponent } from './bookmarks-lists-page/bookmarks-lists-page.component';
import { BookmarksMenuAddListItemComponent } from './bookmarks-menu/bookmarks-menu-add-list-item/bookmarks-menu-add-list-item.component';
import { BookmarksMenuListItemFavoriteComponent } from './bookmarks-menu/bookmarks-menu-list-item/bookmarks-menu-list-item-favorite/bookmarks-menu-list-item-favorite.component';
import { BookmarksMenuListItemComponent } from './bookmarks-menu/bookmarks-menu-list-item/bookmarks-menu-list-item.component';
import { BookmarksMenuListComponent } from './bookmarks-menu/bookmarks-menu-list/bookmarks-menu-list.component';
import { BookmarksMenuComponent } from './bookmarks-menu/bookmarks-menu.component';
import { BookmarksState } from './bookmarks.state';
import { CreateBookmarksListModalComponent } from './create-bookmarks-list-modal/create-bookmarks-list-modal.component';
import { DeleteBookmarksListModalComponent } from './delete-bookmarks-list-modal/delete-bookmarks-list-modal.component';
import { EditBookmarksListMenuComponent } from './edit-bookmarks-list-menu/edit-bookmarks-list-menu.component';
import { RenameBookmarksListModalComponent } from './rename-bookmarks-list-modal/rename-bookmarks-list-modal.component';
import { SortBookmarksListWrapperComponent } from './sort-bookmarks-list-wrapper/sort-bookmarks-list-wrapper.component';
import { SortBookmarksListComponent } from './sort-bookmarks-list/sort-bookmarks-list.component';

@NgModule({
  declarations: [
    AddBookmarksListTeaserComponent,
    BookmarksListPageComponent,
    BookmarksListPageHeaderComponent,
    BookmarksListsPageComponent,
    BookmarksMenuAddListItemComponent,
    BookmarksMenuComponent,
    BookmarksMenuListComponent,
    BookmarksMenuListItemComponent,
    BookmarksMenuListItemFavoriteComponent,
    CreateBookmarksListModalComponent,
    DeleteBookmarksListModalComponent,
    EditBookmarksListMenuComponent,
    RenameBookmarksListModalComponent,
    SortBookmarksListComponent,
    SortBookmarksListWrapperComponent,
  ],
  exports: [
    AddBookmarksListTeaserComponent,
    BookmarksListPageComponent,
    BookmarksListsPageComponent,
    CreateBookmarksListModalComponent,
    EditBookmarksListMenuComponent,
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([BookmarksState]),
    HttpClientModule,
    FormsModule,
    NgbPopoverModule,
    RouterModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    MixedCdkDragDropModule,
    IconButtonComponent,
    ButtonComponent,
    LoadingCircleComponent,
    IconButtonLabelComponent,
    YeLoadingSpinnerComponent,
    BookmarksListTeaserComponent,
    BookmarksListFavoriteTeaserComponent,
    TeaserSliderEmptyMessageComponent,
    CheckmarkSvg,
    ArrowSvg,
    ExitSvg,
    DeleteSvg,
    EditSvg,
    LogoIcon,
    HeartFilledSvg,
    AddSvg,
    ArrowBackSvg,
    PlusSvg,
    VideoTeaserComponent,
    VideoTeaserPlaceholderComponent,
    EmptyFavoriteMessageComponent,
    TeaserSliderComponent,
  ],
})
export class BookmarksModule {}
