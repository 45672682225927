<section class="margin-mobile-view">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>Yoga-Videos für jedes Level</ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-4">
    <ng-container i18n>
      Ob du gerade mit Yoga beginnst oder deine Praxis vertiefen möchtest: Mehr als 2.000 Yoga-Videos, Asana-Tutorials & Meditationen bieten
      die passende Yoga-Sequenz für Anfänger:innen und Fortgeschrittene
    </ng-container>
  </div>

  <app-teaser-slider>
    @for (teaser of videoTeasers; track teaser.id) {
    <app-video-teaser [teaser]="teaser" [size]="(viewport$ | async) === 'desktop' ? 'default' : 'small'"
      class="mr-4 is-hidden-last-child-on-mobile is-hidden-last-child-on-desktop">
    </app-video-teaser>
    }
    <app-more-teaser type="video" href="/video-kategorien" />
  </app-teaser-slider>
</section>