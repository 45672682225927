<div class="show-more-page" *ngIf="bookmarksLists$ | async as bookmarksLists; else loading">
  <div class="headings-box">
    <h3 class="headline">
      <div class="headline-tertiary" i18n>
        Favoritenlisten ({{ bookmarksLists.length }})
      </div>
    </h3>
    <div class="subheadline ml-2" i18n>
      Deine liebsten Videos und selbst erstellte Listen
    </div>
  </div>
  <ul class="list is-flex is-flex-wrap-wrap">
    <li class="search-results">
      <app-bookmarks-list-favorite-teaser [itemsCount]="favoritesVideoCount"></app-bookmarks-list-favorite-teaser>
    </li>
    <li class="search-results">
      <app-add-bookmarks-list-teaser class="teaser"></app-add-bookmarks-list-teaser>
    </li>
    <li *ngFor="let teaser of bookmarksLists" class="search-results">
      <app-bookmarks-list-teaser [teaser]="teaser"></app-bookmarks-list-teaser>
    </li>
  </ul>
</div>
<ng-template #loading>
  <app-ye-loading-spinner *ngIf="loading"></app-ye-loading-spinner>
</ng-template>